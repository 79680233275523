import http from '../clients/Axios'
export default class LaporanKeuanganService {
  async exportLaporanKasPengeluaran(kas_id, start_date, end_date) {
    const params = new URLSearchParams()

    if (kas_id) {
      params.append('filter[kas_id]', kas_id)
    }

    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }

    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }

    return http({
      url: `/account/kas/keluar/report/pengeluaran`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }
  async exportLaporanKasPemasukan(kas_id, start_date, end_date) {
    const params = new URLSearchParams()

    if (kas_id) {
      params.append('filter[kas_id]', kas_id)
    }

    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }

    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }

    return http({
      url: `/account/kas/masuk/report/penerimaan`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }
  async exportLaporanKasBuku(account_id, start_date, end_date) {
    const params = new URLSearchParams()

    params.append('filter[account_id]', account_id)
    params.append('filter[periode_from]', start_date)
    params.append('filter[periode_to]', end_date)

    return http({
      url: `/report/account/buku/print`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }
  async exportLaporanJurnal(account_id, start_date, end_date) {
    const params = new URLSearchParams()

    params.append('filter[account_id]', account_id)

    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }

    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }

    return http({
      url: `/report/account/jurnal/print`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }
  async exportLaporanBukuBesar(account_id, start_date, end_date) {
    const params = new URLSearchParams()

    params.append('filter[account_id]', account_id)
    params.append('filter[periode_from]', start_date)
    params.append('filter[periode_to]', end_date)

    return http({
      url: `/report/account/buku_besar/print`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }
  async exportLaporanTrialBalance(start_date, end_date) {
    const params = new URLSearchParams()
    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }

    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }

    return http({
      url: `/report/account/trial_balance/print`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }
  async exportLaporanRekapJurnal(account_id, start_date, end_date) {
    const params = new URLSearchParams()

    params.append('filter[account_id]', account_id)

    if (start_date) {
      params.append('filter[periode_from]', start_date)
    }

    if (end_date) {
      params.append('filter[periode_to]', end_date)
    }

    return http({
      url: `/report/account/rekap_jurnal/print`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }
  async getLaporanBukuBesar(kas_id, start_date, end_date) {
    return await http.get(
      `/report/account/buku_besar?filter[account_id]=${kas_id}&filter[periode_from]=${start_date}&filter[periode_to]=${end_date}`
    )
  }
  async getLaporanTrialBalance(start_date, end_date) {
    return await http.get(
      `/report/account/trial_balance?filter[periode_from]=${start_date}&filter[periode_to]=${end_date}`
    )
  }
}
